import { Component } from '@angular/core';
import { MetaService } from '../../services/meta.service';
import { DeviceDetectionComponent, WindowService } from '@b2c-frontend/common';

@Component({
  selector: 'b2c-frontend-meta',
  templateUrl: './meta.component.html',
})
export class MetaTagComponent extends DeviceDetectionComponent {
  constructor(protected metaService: MetaService, windowService: WindowService) {
    super(windowService);
  }

  public setMetaData(
    title: string,
    type: string,
    imageUrl?: string,
    description?: string,
    page?: number
  ): void {
    this.metaService.setLinkForCanonicalUrl();
    this.metaService.setOgUrlMetaTag();
    this.metaService.setTitle(title);
    this.metaService.setOgTitleMetaTag(title);
    this.metaService.setOgImageMetaTag(imageUrl ?? '');
    this.metaService.setOgTypeMetaTag(type);
    this.metaService.setDescriptionMetaTag(description ?? '');
    this.metaService.setOgDescriptionMetaTag(description ?? '');
    const canonicalUrl = this.metaService.doc.getElementById('canonical-link')?.href;
    if ((!page || page <= 1) && !canonicalUrl?.includes('&page%3D')) {
      this.metaService.setRobotsMetaTag("index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1");
    } else {
      this.metaService.setRobotsMetaTag("noindex, nofollow");
    }
  }

  public setDateMetaTag(name: string, date: Date): void {
    this.metaService.setDateMetaTag(name, date);
  }
}
