import { DatePipe, DOCUMENT } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { WindowService } from '@b2c-frontend/common';

@Injectable()
export class MetaService {
  private charset = 'UTF-8';
  private window: any;

  constructor(
    @Inject(DOCUMENT) public doc: any,
    @Inject(LOCALE_ID) private localeId: string,
    private windowService: WindowService,
    protected meta: Meta,
    protected title: Title,
  ) {
    this.window = this.windowService.getWindow();
  }

  setLinkForCanonicalUrl() {
    const link: HTMLLinkElement = this.doc.getElementById('canonical-link');
    if (link) {
      this.doc.head.removeChild(link);
    }
    this.generateCanonicalLink(link, this.getUrl());
  }

  setTitle(title: string, includeHostName = true) {
    if (!title) {
      // setTimeout(() => { this.title.setTitle(""); }, 100);
      return;
    }
    if (includeHostName) {
      const hostName = this.getHostName();
      if (!hostName) {
        this.title.setTitle(title);
      } else {
        this.title.setTitle(`${title} | ${hostName}`);
      }
    } else {
      this.title.setTitle(title);
    }
  }

  setDateMetaTag(name: string, date: Date) {
    if (!date) {
      setTimeout(() => {
        this.meta.removeTag("name='" + name + "'");
      }, 100);
      return;
    }
    const datePipe = new DatePipe(this.localeId);
    setTimeout(() => {
      this.meta.updateTag({
        name: name,
        content: datePipe.transform(date, 'YYYY-MM-dd') ?? '',
        charset: this.charset,
      });
    }, 100);
  }

  setOgTypeMetaTag(content: string) {
    if (!content) {
      setTimeout(() => {
        this.meta.removeTag("property='og:type'");
      }, 100);
      return;
    }
    setTimeout(() => {
      this.meta.updateTag({
        property: 'og:type',
        content: content,
        charset: this.charset,
      });
    }, 100);
  }

  setOgTitleMetaTag(content: string) {
    if (!content) {
      setTimeout(() => {
        this.meta.removeTag("property='og:title'");
      }, 100);
      return;
    }
    setTimeout(() => {
      let title = content;
      const hostName = this.getHostName();
      if (hostName) {
        title = `${content} | ${hostName}`;
      }
      this.meta.updateTag({
        property: 'og:title',
        content: title,
        charset: this.charset,
      });
    }, 100);
  }

  setOgImageMetaTag(content: string) {
    if (!content) {
      setTimeout(() => {
        this.meta.removeTag("property='og:image'");
      }, 100);
      return;
    }
    setTimeout(() => {
      this.meta.updateTag({
        property: 'og:image',
        content: content,
        charset: this.charset,
      });
    }, 100);
  }

  setOgUrlMetaTag() {
    setTimeout(() => {
      this.meta.removeTag("property='og:url'");
      this.meta.updateTag({
        property: 'og:url',
        content: this.getUrl(),
        charset: this.charset,
      });
    }, 100);
  }

  setRobotsMetaTag(content: string) {
    if (!content) {
      setTimeout(() => {
        this.meta.removeTag("name='robots'");
      }, 100);
      return;
    }
    setTimeout(() => {
      this.meta.updateTag({
        name: 'robots',
        content: content,
        charset: this.charset,
      });
    }, 100);
  }

  setDescriptionMetaTag(content: string) {
    if (!content) {
      setTimeout(() => {
        this.meta.removeTag("name='description'");
      }, 100);
      return;
    }
    setTimeout(() => {
      this.meta.updateTag({
        name: 'description',
        content: content.replace(/<[^>]*>/g, ''),
        charset: this.charset,
      });
    }, 100);
  }

  setOgDescriptionMetaTag(content: string) {
    if (!content) {
      setTimeout(() => {
        this.meta.removeTag("property='og:description'");
      }, 100);
      return;
    }
    setTimeout(() => {
      this.meta.updateTag({
        property: 'og:description',
        content: content.replace(/<[^>]*>/g, ''),
        charset: this.charset,
      });
    }, 100);
  }

  private getHostName(): string {
    const replacedUrl = this.window.location.hostname.replace('www.', '');
    if (!replacedUrl) {
      return '';
    }

    const transformedOriginUrl = replacedUrl.includes('.')
      ? replacedUrl.substring(0, replacedUrl.indexOf('.'))
      : replacedUrl.substring(0, replacedUrl.length);
    if (!transformedOriginUrl) {
      return '';
    }

    const final =
      transformedOriginUrl.charAt(0).toUpperCase() +
      transformedOriginUrl.slice(1);

    return final;
  }

  private generateCanonicalLink(link: HTMLLinkElement, url: string) {
    link = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('id', 'canonical-link');
    link.setAttribute('href', url);
    this.doc.head.appendChild(link);
  }

  private getUrl(): string {
    let url = this.doc.URL;
    if (!url.includes('?')) {
      return url;
    }

    url = this.doc.URL.substring(0, this.doc.URL.indexOf('?'));
    const searchParams = new URLSearchParams(this.window.location.search);

    const searchTermUrl = this.getSearchTermUrl(searchParams);
    if (searchTermUrl) {
      url += searchTermUrl;
      return url;
    }

    url += this.getBrandUrl(searchParams);
    url += this.getSpecificationUrl(searchParams);

    return url;
  }

  private getSearchTermUrl(searchParams: URLSearchParams): string {
    let url = '';

    if (!searchParams.has('searchTerm')) {
      return url;
    }

    const value: any = this.getQueryParamValue('searchTerm', searchParams);
    if (!value) {
      return url;
    }

    const content = value.replaceAll(' ', '-');
    url += `/st/${content}`;

    return url;
  }

  private getBrandUrl(searchParams: URLSearchParams): string {
    let url = '';

    if (!searchParams.has('brands')) {
      return url;
    }

    const value: any = this.getQueryParamValue('brands', searchParams);
    if (!value) {
      return url;
    }

    const content = value.replaceAll(' ', '-');
    url += `/b/${content}`;

    return url;
  }

  private getSpecificationUrl(searchParams: URLSearchParams): string {
    let url = '';

    if (!searchParams.has('specifications')) {
      return url;
    }

    const specification: any = this.getQueryParamValue('specifications', searchParams);
    if (!specification) {
      return url;
    }

    const regExp = new RegExp(/^[a-zA-Z0-9:; ]+$/i);
    if (regExp.test(specification)) {
      const content = specification.replaceAll(' ', '-').replaceAll(':', '_').replaceAll(';', '---');
      url += `/s/${content}`;
    }

    return url;
  }

  private getQueryParamValue(key: string, searchParams: URLSearchParams): string {
    const value = searchParams.get(key);
    if (Array.isArray(value) && value.length > 0) {
      return value[0];
    }

    return value ?? '';
  }
}
