import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UsersService } from '@b2c-frontend/http';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class BaseLoginComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  loginForm!: FormGroup;
  returnUrl!: string;

  @Input() showDialog!: boolean;

  @Output() registerClick = new EventEmitter<boolean>();
  @Output() outsideClick = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams['returnUrl'] || '/shop';
    this.createLoginForm();
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', Validators.required),
    });
  }

  onOpenRegister() {
    this.registerClick.emit(true);
  }

  onOutsideClick() {
    this.outsideClick.emit(true);
  }

  onSubmit() {
    const values = this.loginForm.value;

    const isValid = values.email.target?.value && values.password.target?.value;

    if (!isValid) {
      return;
    }

    const request = {
      username: values.email.target.value,
      password: values.password.target.value,
    };

    this.usersService.login(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (result) => {
        if (result) {
          this.authService.setToken(result);
          this.loginForm.reset();
          this.showDialog = false;
          this.router.navigate(['/']);
        }
      }
    });
  }
}
