<header [style.position]="isScrolled ? 'fixed' : ''">
  <!-- TOP BAR -->
  <div class="header-top-bar container" [style.display]="!showHeaderTopBar ? 'none': ''">
    <div class="d-flex">
      <div class="phone">
        <i class="fa fa-solid fa-mobile-screen-button"></i>&nbsp;&nbsp;
        <a class="header-top-a"
          href="tel:{{companyViewService.company?.phoneNumber}}">{{companyViewService.company?.phoneNumber}}</a>
      </div>
      <div class="mail">
        <i class="fa fa-regular fa-envelope"></i>&nbsp;&nbsp;
        <a class="header-top-a" href="mailto:{{companyViewService.company?.email}}">Pisi nam</a>
      </div>
    </div>
    <div class="status">
      <div class="status" (click)="onOpenLogin()">
        <i class="fa fa-solid fa-truck-moving"></i>&nbsp;&nbsp;<a class="header-top-a"
          routerLink="account/orders">Status porudžbine</a>
      </div>
    </div>
  </div>
  <!-- CENTRAL HEADER -->
  <div class="container header-center">
    <div class="hamburger-wrapper" [style.display]="!showCentralHeader ? 'none' : ''">
      <ng-container *ngIf="!dropdownMenuOpened; else closeIcon">
        <div class="hamburger-line" (click)="onMenuToggle()">
          <div class="line-white"></div>
          <div class="line-white-shorter"></div>
          <div class="line-white"></div>
        </div>
      </ng-container>
      <ng-template #closeIcon>
        <img src="../../../assets/images/header/x-button-white.svg" alt="x-button" (click)="onCloseMenu()" class="x-btn">
      </ng-template>
      <div class="logo-image">
        <a routerLink="/">
          <img [src]="companyViewService.company?.logo" [alt]="companyViewService.company?.name" width="123px">
        </a>
      </div>
      <lib-ui-template-menu-dropdown 
        [categories]="categories"
        [dropdownMenuOpened]="dropdownMenuOpened"
        [topPosition]="'6rem'"
        (dropdownOpen)="onDropdownOpen($event)"></lib-ui-template-menu-dropdown>
    </div>
    <div class="logo-image" *ngIf="showLogo" style="margin-right: 44px;">
      <a routerLink="/">
        <img [src]="companyViewService.company?.logo" [alt]="companyViewService.company?.name" width="123px">
      </a>
    </div>
    <lib-ui-template-nav-bar-search-input style="width: 100%;" [showSearchDropdown]="showSearchDropdown"
      *ngIf="showSearchInput" (searchDropdownShow)="onSearchDropdownShow($event)"></lib-ui-template-nav-bar-search-input>
    <div class="basket-wrapper">
      <div class="d-flex justify-content-end gap-4" *ngIf="!isMobile">
        <div class="wishlist" (click)="onOpenWishlistLogin()" *ngIf="!authSertice.isAuthenticated()">
          <div class="header-icon-wrapper">
            <div class="header-icon-number yellow-black" *ngIf="wishItemsCount">{{wishItemsCount}}</div>
            <img src="../../../assets/images/header/hearth.svg" alt="hearth">
          </div>
          <span class="icon-text">Lista želja</span>
        </div>
        <div class="account" (click)="onOpenLogin()" *ngIf="!authSertice.isAuthenticated()">
          <div class="header-icon-wrapper">
            <img src="../../../assets/images/header/account.svg" alt="account">
          </div>
          <span class="icon-text" style="align-self: center;">Prijava</span>
        </div>
        <div class="account" routerLink="/account/user-data" *ngIf="authSertice.isAuthenticated()">
          <div class="header-icon-wrapper">
            <img src="../../../assets/images/header/account.svg" alt="account">
          </div>
          <span class="icon-text" style="align-self: center;">{{authSertice.firstName}}</span>
        </div>
        <div class="basket" style="position: relative !important;">
          <div class="header-icon-wrapper" (click)="onOpenBasketSide()">
            <div class="header-icon-number" *ngIf="basketItemsCount">{{basketItemsCount}}</div>
            <img src="../../../assets/images/header/basket-white.svg" alt="basket-white">
          </div>
          <span style="align-self: center;" (click)="onOpenBasketSide()">Korpa</span>
          <div class="basket-side-wrapper-absolute">
            <lib-ui-template-basket-side-view [opened]="basketViewService.showBasketSide"
              (basketSideClosed)="closeBasketSide()"></lib-ui-template-basket-side-view>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end gap-4" *ngIf="isMobile">
        <div (click)="onOpenWishlistLogin()" *ngIf="!authSertice.isAuthenticated()">
          <div class="header-icon-wrapper">
            <div class="header-icon-number yellow-black" *ngIf="wishItemsCount">{{wishItemsCount}}</div>
            <img src="../../../assets/images/header/hearth.svg" alt="hearth">
          </div>
        </div>
        <div class="header-icon-wrapper">
          <img src="../../../assets/images/header/account.svg" alt="account" (click)="onOpenLogin()"
            *ngIf="!authSertice.isAuthenticated()">
          <img src="../../../assets/images/header/account.svg" alt="account" routerLink="/account/user-data"
            *ngIf="authSertice.isAuthenticated()">
        </div>
        <div class="basket" style="position: relative !important;">
          <div class="header-icon-wrapper" (click)="onOpenBasketSide()">
            <div class="header-icon-number" *ngIf="basketItemsCount">{{basketItemsCount}}</div>
            <img src="../../../assets/images/header/basket-white.svg" alt="basket-white">
          </div>
          <div class="basket-side-wrapper-absolute">
            <lib-ui-template-basket-side-view [opened]="basketViewService.showBasketSide"
              (basketSideClosed)="closeBasketSide()"></lib-ui-template-basket-side-view>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container header-center" *ngIf="!showSearchInput">
    <lib-ui-template-nav-bar-search-input style="width: 100%;margin-bottom: 1rem;"
      [showSearchDropdown]="showSearchDropdown" (searchDropdownShow)="onSearchDropdownShow($event)"></lib-ui-template-nav-bar-search-input>
  </div>
  <!-- BOTTOM HEADER -->
  <div class="header-bottom" [style.display]="!showBottomHeader ? 'none' : ''">
    <div class="container d-flex align-items-center">
      <div class="header-bottom-left">
        <div class="hamburger-wrapper">
          <ng-container *ngIf="!dropdownMenuOpened; else closeIcon">
            <div class="hamburger-line" (click)="onMenuToggle()">
              <div class="line"></div>
              <div class="line-shorter"></div>
              <div class="line"></div>
            </div>
          </ng-container>
          <ng-template #closeIcon>
            <img src="../../../assets/images/header/x-button.svg" alt="x-button" (click)="onCloseMenu()" class="x-btn">
          </ng-template>
          <span class="all-categories-label">Sve kategorije</span>
          <lib-ui-template-menu-dropdown
            [categories]="categories"
            [dropdownMenuOpened]="dropdownMenuOpened"
            [topPosition]="'4rem'"
            (dropdownOpen)="onDropdownOpen($event)"></lib-ui-template-menu-dropdown>
        </div>
      </div>
      <div class="header-bottom-right">
        <ul class="vertical-center">
          <li>
            <a (click)="navigateTo('/ps', { withPromoPrice: true })" routerLinkActive="active-header-li">Akcije</a>
          </li>
          <li>
            <a (click)="navigateTo('/ps', { showNewProducts: true })" routerLinkActive="active-header-li">Novo u ponudi</a>
          </li>
          <li>
            <a (click)="navigateTo('/page/blogs')" routerLinkActive="active-header-li">Blogovi</a>
          </li>
          <li>
            <a (click)="navigateTo('/page/contact')" routerLinkActive="active-header-li">Kontakt</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="showOutsideContainer" (click)="onCloseMenu()" class="outside-container"></div>
</header>
<lib-ui-template-login (registerClick)="onOpenRegister()" (outsideClick)="this.loginOpened = false"
  [showDialog]="loginOpened"></lib-ui-template-login>
<lib-ui-template-register (loginClick)="onOpenLogin()" (outsideClick)="this.registerOpened = false"
  *ngIf="registerOpened"></lib-ui-template-register>
<lib-ui-template-wihslist-login (wishlistLoginClick)="onOpenWishlistLogin()"
  (outsideClick)="this.wishlistLoginOpened = false" [showDialog]="wishlistLoginOpened"></lib-ui-template-wihslist-login>