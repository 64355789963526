import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { WindowService } from '@b2c-frontend/common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'b2c-frontend-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  private window: any;

  @Output() pageChanged = new EventEmitter<number>();
  @Input() totalPages = 1;
  @Input() config!: PaginationInstance;

  constructor(private activatedRoute: ActivatedRoute, private windowService: WindowService) { }

  ngOnInit(): void {
    this.window = this.windowService.getWindow();
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: params => {
        const page = params['page'] ? <number>params['page'] : 1;
        this.config.currentPage = page;
      }
    });
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  onCurrentPageChanged(page: number) {
    this.config.currentPage = page;
    this.pageChanged.emit(page);
  }
  
  getHref(page: number): string {
    if (page > 1) {
      return '';
    }
    return `${this.window.location.pathname}`;
  }
}

