import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, EmailService, UsersService } from '@b2c-frontend/http';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-ui-template-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class BaseRegisterComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  @Output() loginClick = new EventEmitter<boolean>();
  @Output() outsideClick = new EventEmitter<boolean>();

  registerForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private usersService: UsersService,
    private emailService: EmailService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.createRegisterForm();
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      termsAndConditions: [false, [Validators.required]],
      subscribeToPromo: [false, [Validators.required]]
    });
  }

  onOpenLogin() {
    this.loginClick.emit(true);
  }

  onSubmit() {
    const values = this.registerForm.value;

    const isValid = values.email.target.value &&
                    this.emailService.isEmailValid(values.email.target.value) &&
                    values.password.target.value &&
                    values.confirmPassword.target.value &&
                    values.firstName.target.value &&
                    values.lastName.target.value &&
                    values.phoneNumber.target.value &&
                    values.termsAndConditions;

    if (!isValid) {
      return;
    }

    if (values.password.target.value !== values.confirmPassword.target.value) {
      this.toastr.warning(/*$localize*/`Lozinke nisu iste`, /*$localize*/`Notifikacija`);
      return;
    }

    const request = {
      email: values.email.target.value,
      password: values.password.target.value,
      firstName: values.firstName.target.value,
      lastName: values.lastName.target.value,
      phoneNumber: values.phoneNumber.target.value,
      subscribeToPromo: values.subscribeToPromo
    };

    this.usersService.register(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (result) => {
        if (result) {
          this.authService.setToken(result);
          this.registerForm.reset();
          this.router.navigate(['/']);
          this.outsideClick.emit(true);
        }
      }
    });
  }

  onOutsideClick() {
    this.outsideClick.emit(true);
  }
}
