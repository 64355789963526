<div class="container carousel-wrap mb-2">
    <div *ngIf="showSlidersLoader" class="global-loader loader-center"></div>
    <owl-carousel-o *ngIf="!showSlidersLoader && sliders && sliders.length" [options]="bannerOptions">
        <ng-container *ngFor="let slide of sliders">
            <ng-template carouselSlide [id]="slide.id" #slideTemplate>
                <div (click)="navigateToFrame(slide)" style="cursor: pointer;">
                    <lib-ui-template-frame-image [imageUrl]="slide.imageUrl" [cssClasses]="'banner-img'"
                        [altTitle]="slide.title">
                    </lib-ui-template-frame-image>
                </div>
            </ng-template>
            <b2c-frontend-a [relativeHref]="getHrefFrame(slide)" [content]="slideTemplate">
            </b2c-frontend-a>
        </ng-container>
    </owl-carousel-o>
</div>
<section class="container" *ngIf="popularCategoriesFrame$ | async as popularCategoriesFrame">
    <div class="row" *ngIf="popularCategories && popularCategories.length > 0">
        <div class="d-flex section-title">
            <h2 class="col-md-3">{{popularCategoriesFrame.title}}</h2>
        </div>
        <div class="d-flex action-offer justify-content-center action-swiper">
            <owl-carousel-o [options]="customOptionsCategories">
                <ng-container *ngFor="let category of popularCategories">
                    <ng-template carouselSlide [id]="category.id">
                        <lib-ui-template-category-card [category]="category"></lib-ui-template-category-card>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</section>
<section class="featured container" *ngIf="productsOnSaleFrame$ | async as productsOnSaleFrame">
    <div class="row" *ngIf="productsOnSale && productsOnSale.length > 0">
        <div class="d-flex section-title">
            <h2 class="col-md-3">{{productsOnSaleFrame?.title}}</h2>
        </div>
        <div class="d-flex action-offer justify-content-center action-swiper">
            <owl-carousel-o [options]="customOptions">
                <ng-container *ngFor="let product of productsOnSale">
                    <ng-template carouselSlide [id]="product.id">
                        <lib-ui-template-product-card [product]="product"></lib-ui-template-product-card>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</section>
<section class="container mt-3" *ngIf="popularCategoryFrame$ | async as popularCategoryFrame">
    <div class="container-content">
        <div class="row section-5">
            <h2>{{popularCategoryFrame.title}}</h2>
            <div class="col-lg-3  section-5-left"
                *ngIf="popularCategoriesInCategoryFrame && popularCategoriesInCategoryFrame.length > 0">
                <div class="col-12 section-5-categories">
                    <lib-ui-template-frame-image [imageUrl]="popularCategoryFrame.imageUrl"
                        [cssClasses]="'section-5-image'" [altTitle]="popularCategoryFrame.title">
                    </lib-ui-template-frame-image>
                </div>
            </div>
        </div>
        <div class="container">
            <ul class="section-5-ul">
                <li class="section-5-li" *ngFor="let category of popularCategoriesInCategoryFrame">
                    <ng-template #categoryTemplate>
                        <span class="section-5-a" (click)="navigateToCategory(category)" style="cursor: pointer;">
                            {{category.name}}
                        </span>
                    </ng-template>
                    <b2c-frontend-a [relativeHref]="getHrefCategory(category)" [content]="categoryTemplate">
                    </b2c-frontend-a>
                </li>
            </ul>
            <div class="col-lg-6  section-5-right"
                *ngIf="popularProductsInCategoryFrame && popularProductsInCategoryFrame.length > 0">
                <div class="section-5-products">
                    <lib-ui-template-product-card *ngFor="let product of popularProductsInCategoryFrame"
                        [product]="product">
                    </lib-ui-template-product-card>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-container *ngIf="oneCategoryFrame$ | async as oneCategoryFrame">
    <div class="select-section mt-5" *ngIf="oneCategoryProducts && oneCategoryProducts.length">
        <section class="container">
            <div class="row" *ngIf="oneCategoryFrame">
                <div class="d-flex mt-3 section-title">
                    <h2 class="col-md-3">{{oneCategoryFrame.title}}</h2>
                </div>
                <div class="d-flex action-offer justify-content-center popular-swiper">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let product of oneCategoryProducts">
                            <ng-template carouselSlide [id]="product.id">
                                <lib-ui-template-product-card [product]="product"></lib-ui-template-product-card>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </section>
    </div>
</ng-container>
<ng-container *ngIf="bannersFrame$ | async as bannersFrame">
    <section class="container mt-3" *ngIf="bannersFrame.items && bannersFrame.items.length > 0">
        <h2>{{bannersFrame.items[0].title}}</h2>
        <div class="banner">
            <div class="mb-2" *ngFor="let banner of bannersFrame.items">
                <ng-template [id]="banner.id" #bannerTemplate>
                    <div (click)="navigateToFrame(banner)" style="cursor: pointer;">
                        <lib-ui-template-frame-image [imageUrl]="banner.imageUrl" [cssClasses]="'custom-img'"
                            [altTitle]="banner.title">
                        </lib-ui-template-frame-image>
                    </div>
                </ng-template>
                <b2c-frontend-a [relativeHref]="getHrefFrame(banner)" [content]="bannerTemplate">
                </b2c-frontend-a>
            </div>
        </div>
    </section>
</ng-container>
<div class="select-section mt-5" *ngIf="mostSoldProducts$ | async as mostSoldProducts">
    <section class="container" *ngIf="mostSoldProducts.length > 0">
        <div class="row">
            <div class="d-flex mt-3 section-title">
                <h2 class="col-md-3">Najprodavaniji proizvodi</h2>
            </div>
            <div class="d-flex action-offer justify-content-center popular-swiper">
                <owl-carousel-o [options]="customOptions">
                    <ng-container *ngFor="let product of mostSoldProducts">
                        <ng-template carouselSlide [id]="product.id">
                            <lib-ui-template-product-card [product]="product"></lib-ui-template-product-card>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </section>
</div>
<section class="container mt-3" *ngIf="recommendationFrames$ | async as recommendationFrames">
    <div *ngIf="recommendationFrames.items && recommendationFrames.items.length > 0">
        <h2>{{recommendationFrames.items[0].title}}</h2>
        <div class="suggestion">
            <div class="suggestion-row">
                <div class="suggestion-col" *ngFor="let frame of recommendationFrames.items">
                    <lib-ui-template-frame-card [frame]="frame" [pagePrefix]="'page'"></lib-ui-template-frame-card>
                </div>
            </div>
        </div>
    </div>
</section>