import { FrameType, OrderStatus, PaymentMethod } from "./enums";

export class BasketViewModel {
  basketId!: string;
  total!: number;
  itemsCount!: number;
  transportCost!: number;
  totalSaving?: number;
  items!: BasketItemViewModel[];
}

export class BasketItemViewModel {
  productId!: string;
  productUniqueId!: string;
  productName!: string;
  price!: number;
  isPromoPriceActive!: boolean;
  promoPrice?: number;
  quantity!: number;
  sum!: number;
  linkedProductId?: string;
  productPictureUrl?: string;
}

export class CategoryListViewModel {
  id!: string;
  name!: string;
  slug!: string;
  parentId?: string;
  parentName?: string;
  parentSlug?: string;
  imageUrl?: string;
  shortDescription?: string;
  urlQueryParameters?: string;
  priority!: number;
  open!: boolean;
  hasProducts!: boolean;
  hasCategories!: boolean;
  path!: string;
  loaded?: boolean;
  children?: CategoryListViewModel[];

  static getSlug(category: CategoryListViewModel): string | undefined {
    return category.slug;
  }
}

export class CategoryGroupSelectViewModel {
  id!: string;
  name!: string;
  priceMargin?: number;
}

export class CompanyViewModel {
  name!: string;
  defaultCurrency!: string;
  taxNumber!: string;
  identificationNumber!: string;
  transportCostLimit!: number;
  fullAddress?: string;
  email?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  logo?: string;
  webSiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  currentBankAccounts?: string;
  onlinePaymentUrl?: string;
  onlinePaymentShopId?: string;
  enableOnlinePaymentApi!: boolean;
  payPalClientId?: string;
  payPalCurrency?: string;
  enablePayPalApi!: boolean;
}

export class FrameViewModel {
  id!: string;
  title!: string;
  slug!: string;
  imageUrl?: string;
  frameType!: FrameType;
  shortDescription!: string;
  code?: string;
  description?: string;
}

export class OrderListViewModel {
  id!: string;
  number!: string;
  fullName!: string;
  companyName?: string;
  status!: OrderStatus;
  paymentMethod!: PaymentMethod;
  totalPrice!: number;
}

export class ProductListViewModel {
  id!: string;
  uniqueId!: string;
  name!: string;
  price!: number;
  slug!: string;
  transportCost!: number;
  isNew!: boolean;
  saving?: number;
  isPromoPriceActive!: boolean;
  isInWishList!: boolean;
  promoPrice?: number;
  recommendedPrice?: number;
  pictureUrls?: string[];
  mainPictureUrl?: string;
  discountInPercentage?: number;
  selected?: boolean;
  availability?: string;
  stickerFrames?: FrameViewModel[];
  categories?: CategoryProductViewModel[];
  insignificantSpecification?: InsignificantSpecificationViewModel;
}

export class InsignificantSpecificationViewModel {
  name?: string;
  value?: string;
}

export class ProductDetailsViewModel {
  id!: string;
  productId!: string;
  name!: string;
  code!: string;
  barCode!: string;
  slug!: string;
  supplierName!: string;
  price!: number;
  installmentsPrice!: number;
  transportCost!: number;
  isNew!: boolean;
  isPromoPriceActive!: boolean;
  promoPrice?: number;
  saving?: number;
  url?: string;
  availability?: string;
  currency?: string;
  mainPictureUrl?: string;
  description?: string;
  specificationsAsString?: string;
  promoPriceStartDate?: Date;
  promoPriceEndDate?: Date;
  discountInPercentage?: number;
  categories?: CategoryProductViewModel[];
  specifications?: SpecificationProductViewModel[];
  groupedSpecifications?: SpecificationGroupViewModel[];
  stickerFrames?: FrameViewModel[];
  imageUrls?: ImageUrlViewModel[];
}

export class ImageUrlViewModel {
  url!: string;
  safeUrl?: any;
  isSelected!: boolean;
  is3D!: boolean;
  isVideo!: boolean;
}

export class SpecificationGroupViewModel {
  name?: string;
  specifications?: SpecificationProductViewModel[];
}


  export class CategoryProductViewModel {
    categoryId!: string;
    parentId?: string;
    name!: string;
    slug!: string;
    imageUrl?: string;
    title?: string;
    shortDescription?: string;
    description?: string;
    priority!: number;
    selected!: boolean;
    hasChildren!: boolean;
    createdAt!: Date;
    updatedAt?: Date;
  }

  export class SpecificationProductViewModel {
    name?: string;
    value?: string;
    group?: string;
  }

  export class WishViewModel {
    wishId!: string;
    userId!: string;
    items!: WishItemViewModel[];
  }

  export class WishItemViewModel {
    productId!: string;
    productName!: string;
    price!: number;
    promoPrice?: number;
    productPictureUrl?: string;
  }

  export class UserViewModel {
    id!: string;
    username!: string;
    firstName!: string;
    lastName!: string;
    fullName!: string;
    street!: string;
    number!: string;
    city!: string;
    postalCode!: string;
    state!: string;
    floor?: string;
    apartmentNumber?: string;
    companyName?: string;
    companyTaxNumber?: string;
    email?: string;
    phoneNumber?: string;
    mobilePhoneNumber?: string;
  }

  export class SpecificationListViewModel {
    name!: string;
    priority!: number;
    originalNames!: string;
    areValuesLoaded!: boolean;
    values!: SpecificationValueListViewModel[];
    isOpened!: boolean;
    hide!: boolean;
  }

  export class SpecificationValueListViewModel {
    mappingName!: string;
    originalNames!: any;
  }

  export class CategorySearchedListViewModel {
    categoryId!: string;
    name!: string;
    slug!: string;
    priority!: number;
    products!: ProductListViewModel[];
  }

  export class PlacesViewModel {
    postalCode!: string;
    street!: string;
    city!: string;
    standardAvailable!: boolean;
    cargoAvailable!: boolean;
  }

  export class BrandViewModel {
    brand!: string;
    lastModifiedAt!: Date;
  }

  export class PaginationViewModel<TItem> {
    totalPages!: number;
    pageSize!: number;
    totalCount!: number;
    items!: TItem[];
  }
  
  export class ProductListPaginationViewModel extends PaginationViewModel<ProductListViewModel> {
    productIds?: string[];
  }