import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  CompanyViewService,
  CategoryListViewModel,
  CategoriesService,
} from '@b2c-frontend/http';
import { Router } from '@angular/router';
import { DeviceDetectionComponent, WindowService } from '@b2c-frontend/common';

@Component({
  selector: 'lib-ui-template-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class BaseMenuDropdownComponent extends DeviceDetectionComponent implements OnInit {
  isTablet = false;

  @Input() dropdownMenuOpened = false;
  @Input() topPosition = '';
  @Input() categories: CategoryListViewModel[] = [];
  @Output() dropdownOpen = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private categoriesService: CategoriesService,
    public companyViewService: CompanyViewService,
    windowService: WindowService,
  ) {
    super(windowService);
  }

  ngOnInit(): void {
    this.isTablet = this.window.innerWidth <= 1280;
  }

  onMainCategoryMouseEnter(category: CategoryListViewModel) {
    this.openCategory(category);
  }

  onMainCategoryClicked(category: CategoryListViewModel) {
    if (this.isTablet) {
      this.onMainCategoryMouseEnter(category);
      return;
    }
    if (!category.open) {
      this.openCategory(category);
    } else {
      this.router.navigate(
        [`/ps/c/${CategoryListViewModel.getSlug(category)}`],
        { queryParams: { categoryId: category.id } },
      );
      this.dropdownMenuOpened = false;
      this.dropdownOpen.emit(this.dropdownMenuOpened);
    }
  }

  seeAllMainCategories(category: CategoryListViewModel) {
    this.dropdownMenuOpened = false;
    this.router.navigate([`/ps/c/${CategoryListViewModel.getSlug(category)}`], {
      queryParams: { categoryId: category.id },
    });
    this.dropdownOpen.emit(this.dropdownMenuOpened);
  }

  onSubCategoryClicked(category: CategoryListViewModel) {
    this.dropdownMenuOpened = false;
    this.router.navigate([`/ps/c/${CategoryListViewModel.getSlug(category)}`], {
      queryParams: { categoryId: category.id },
    });
    this.dropdownOpen.emit(this.dropdownMenuOpened);
  }

  onAllCategoriesClicked(category: CategoryListViewModel) {
    this.dropdownMenuOpened = false;
    this.router.navigate([`/ps/c/${CategoryListViewModel.getSlug(category)}`], {
      queryParams: { categoryId: category.id },
    });
    this.dropdownOpen.emit(this.dropdownMenuOpened);
  }

  getHref(category: CategoryListViewModel) {
    return `/ps/c/${CategoryListViewModel.getSlug(category)}`;
  }

  navigateTo(route: string, queryParams?: any) {
    this.router.navigate([route], { queryParams: queryParams });
    this.dropdownMenuOpened = false;
    this.dropdownOpen.emit(this.dropdownMenuOpened);
  }

  private openCategory(category: CategoryListViewModel) {
    this.categories.forEach((c) => (c.open = false));
    category.open = true;
    this.loadChildren(category);
  }

  private loadChildren(category: CategoryListViewModel) {
    if (!category.children || !category.children.length) {
      return;
    }

    category.children.forEach(child => {
      if (!child.loaded) {
        this.categoriesService.getCategories(child.id)
        .subscribe({
          next: (children) => {
            child.children = children;
            child.loaded = true;
          },
        });
      }
    });
  }
}
