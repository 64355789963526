<pagination-template #paging="paginationApi" (pageChange)="onCurrentPageChanged($event)"
  (pageBoundsCorrection)="onCurrentPageChanged($event)" [ngClass]="totalPages > 1 ? 'show-paging' : 'hide-paging'">
  <div class="col-12">
    <nav aria-label="navigation">
      <ul class="pagination">
        <li rel="prev" class="page-item" [class.disabled]="paging.isFirstPage()">
          <button class="page-link" type="button" aria-label="Prethodni"
            [attr.disabled]="paging.isFirstPage() ? '' : null" (click)="paging.previous()">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        <li class="page-item" *ngFor="let page of paging.pages">
          <ng-container *ngIf="getHref(page.value)">
            <ng-template #buttonTemplate>
              <button class="page-link" type="button" [attr.aria-label]="page.label"
                [class.active]="paging.getCurrent() === page.value"
                (click)="paging.setCurrent(page.value)">{{page.label}}</button>
            </ng-template>
            <b2c-frontend-a [relativeHref]="getHref(page.value)"
              [rel]="page.value === paging.getCurrent() ? null : (page.value < paging.getCurrent() ? 'prev' : 'next')"
              [content]="buttonTemplate">
            </b2c-frontend-a>
          </ng-container>
          <ng-container *ngIf="!getHref(page.value)">
            <button class="page-link" type="button" [attr.aria-label]="page.label"
              [class.active]="paging.getCurrent() === page.value"
              (click)="paging.setCurrent(page.value)">{{page.label}}</button>
          </ng-container>
        </li>
        <li rel="next" class="page-item" [class.disabled]="paging.isLastPage()">
          <button class="page-link" type="button" aria-label="Sledeći" [attr.disabled]="paging.isLastPage() ? '' : null"
            (click)="paging.next()">
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</pagination-template>